import { Model } from "@vuex-orm/core";
import BaseModel from "./BaseModel";
import Requests from "./Requests";

export default class CompanyCorrespondence extends BaseModel {
  static entity = "CompanyCorrespondence";

  static fields() {
    return {
      id: this.attr(null),
      filename: this.attr(""),
      created_at: this.attr(null),
      type: this.attr(""),
      link: this.attr(""),
      size: this.attr(null),
      read: this.attr(null),
      company_id: this.attr(null),
    };
  }


  static async fetchCompanyCorrespondence(id, loadingAll) {
    let { data } = await this.axios.get(`/companies/${id}/files`)
    for(var i = 0; i < data.length; i++)
      data[i].company_id = parseInt(id)
    
    if(loadingAll)
      Requests.markAsLoaded('CompanyCorrespondence') 
    
    this.insertOrUpdate({ data });
  }

}
