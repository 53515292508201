import BaseModel from "./BaseModel";
import queryString from "query-string";
import Requests from "@/models/Requests";


export default class User extends BaseModel {
  static entity = "profile";

  static findByUrl(params) {
    let url = `/${this.entity}`;
    if (params && Object.keys(params).length > 0) {
      url += "?" + queryString.stringify(params);
    }
    return url;
  }

  static async findOrCreate(params) {
    let { data } = await this.axios.get(this.findByUrl(params));
    await this.create({ data });
    return this.query().first();
  }

  // authentication methods
  static async login(user) {
    return this.axios
      .post("/sessions", { user })
      .then(({ data }) => this.create({ data }));
  }

  static async logout() {
    return this.axios
      .delete("/sessions")
      .then(({ data }) => this.create({ data }));
  }
  static async createAccount(user) {
    return this.axios
      .post("/registrations", { user })
      .then(({ data }) => this.create({ data }));
  }
  static async updateAccount(user) {
    let formData = new FormData();

    Object.keys(user).forEach((k) => {
      formData.append(k, user[k]);  
    })

    return this.axios
      .patch("/profile", formData)
      .then(({ data }) => {
        this.create({ data })
      });
  }

  static async updatePassword(passwordInfo) {
    let formData = new FormData();

     Object.keys(passwordInfo).forEach((k) => {
       formData.append(k, passwordInfo[k]);  
    })
    return this.axios
      .patch("/change_password", formData)
      .then(({ data }) => {
        this.create({ data })
      });
  }

  static async resetPassword(passwordInfo) {
    let formData = new FormData();

    Object.keys(passwordInfo).forEach((k) => {
      formData.append(k, passwordInfo[k]);  
   })
   return this.axios
   .post("/passwords/reset", formData);
  }

  static async forgotPassword(email) {
    return this.axios.post("/passwords/confirmation", {
      email,
    });
  }

  static async getProfile() {
    try {
      let { data } = await this.axios.get(`/profile`);
      await this.create({ data });
      Requests.markAsLoaded('profile')
    } catch (e) {
      console.error(e)
      Requests.markAsErrorOccurred('profile', 'failed to load profile')
    }
  }

  static async cancelSubscription() {
    let { data } = await this.axios.delete(`/cancel`);
    await this.create({ data });
    location.reload();
  }

  static get current() {
    return this.query().first();
  }

  static fields() {
    return {
      id: this.attr(null),
      first_name: this.attr(""),
      last_name: this.attr(""),
      email: this.attr(""),
      phone: this.attr(""),
      planId: this.attr(null),
      plan_id: this.attr(null),
      is_pro: this.attr(false),
      companies: this.attr([]),
      cards: this.attr([]),
      avatar_url: this.attr(null),
      bing_conversion: this.attr(null),
      subscription_access: this.attr(null),
      unfinished_order: this.attr(null),
      facebook_conversion: this.attr(null),
      locale_id: this.attr(null),
      checkout_test: this.attr(null),
      documents_in_progress: this.attr(null),
      business_formation_incomplete_items: this.attr(null),
      business_formation_companies_being_formed: this.attr(null),
      registered: this.attr(null),
      form_abandon_test: this.attr(null),
      form_test: this.attr(null),
      default_state: this.attr(null),
      is_admin: this.attr(false),
      access_until: this.attr(""),
      apple_pay_eligible: this.attr(false),
    };
  }

  async createOrUpdateRecurlyAccount(paymentData) {
    return this.constructor.axios.post("/billing_infos", paymentData);
  }

  async editCard(paymentData, id) {
    return this.constructor.axios.patch(`/billing_infos/${id}`, paymentData);
  }

  async createRecurlySubscription(params) {
    return this.constructor.axios.post("/recurly_subscriptions", params);
  }

  static async createRandomUser(stripe) {
    let { data } = await this.axios.get(`/creator/user.json`, {
      params: {
        ...(stripe ? { stripe: stripe } : {}),
      }
      });
    this.insert({ data });
    this.login({data});
    this.getProfile();
    location.reload();
  }

}
